





















@import '../../../styles/login.styl'
@media screen and (min-width: 641px)
  .login-grid
    background-image url('../../../assets/kinto/login.png')
    background-size:cover
